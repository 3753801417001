import React from 'react';
import ReactDOM from 'react-dom';
import Orders 	from './Orders';
import MessageBox from './MessageBox';
import { createBrowserRouter, RouterProvider , Navigate , useParams } from "react-router-dom";
import { MessageContext , MessageProvider } from './MessageContext';

const router = createBrowserRouter(
[
    {
		path: "/",
		element: <Navigate to="orders/p1" /> ,
	},
    {
		path: "orders/:page" ,
		element: <Orders/> ,
		// children:
		// [
			// {
				// path: ":page",
				// element: <Orders/> ,
			// },
		// ],
	},
    // {
		// path: "*",
		// element: <Navigate to="orders/p1" /> ,
	// },
]);

// function Root()
// {
	// return(
		// <div className="App">
		// </div>
	// );

  // return(
    // <div className="App">
      // <header className="App-header">
        // <img src={logo} className="App-logo" alt="logo" />
        // <p>
          // Edit <code>src/App.js</code> and save to reload.
        // </p>
        // <a
          // className="App-link"
          // href="https://reactjs.org"
          // target="_blank"
          // rel="noopener noreferrer"
        // >
          // Learn React
        // </a>
      // </header>
    // </div>
  // );

// }

function MsgBox()
{
	const { messages , addMessage } = React.useContext( MessageContext );

	return messages && messages.length && <MessageBox/>
}

function App()
{
	return(
		<MessageProvider>
			<div>
				<RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
				<MsgBox/>
			</div>
		</MessageProvider>
	);
}

export default App;
