import React, { useRef, useState } from 'react';
import { MessageContext } from './MessageContext';
import './MessageBox.css';

const MessageBox = () =>
{
	const { messages, clearMessages } = React.useContext(MessageContext);
	const boxRef = useRef(null);
	const [isDragging, setIsDragging] = useState(false);
	const [offset, setOffset	] = useState({ x: 0, y: 0 });
	const [position, setPosition] = useState(
	{
		left: window.innerWidth  / 2 - 300 ,
		top:  window.innerHeight / 2 - 300 ,
	} );

	React.useEffect( () =>
	{
		console.log('boxRef has been initialized:', boxRef.current);
		if( boxRef.current )
		{
			setPosition(
			{
				left: window.innerWidth  / 2 - boxRef.current.getBoundingClientRect().width  / 2 ,
				top:  window.innerHeight / 2 - boxRef.current.getBoundingClientRect().height / 2 ,
			} );
		}
	} , [] );

	const handleMouseDown = (e) =>
	{
		setIsDragging( true );
		setOffset(
		{
			x: e.clientX - boxRef.current.getBoundingClientRect().left,
			y: e.clientY - boxRef.current.getBoundingClientRect().top ,
			// x: e.clientX - position.left ,
			// y: e.clientY - position.top  ,
		} );
	};

	const handleMouseMove = (e) =>
	{
		if( isDragging )
			setPosition(
			{
				left: e.clientX - offset.x,
				top:  e.clientY - offset.y,
			} );
	};

	const handleMouseUp = () =>
	{
		setIsDragging( false );
	};

// ref={setBoxRef}
	return (
		<div
			className="message-box"
			ref={boxRef}
			style={{ top: position.top, left: position.left, position: 'fixed' }}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseUp} // در صورتی که ماوس از جعبه خارج شود
		>
			<h4 className="message-box-title">Messages</h4>
			<div className="message-content">
			{
				messages.length === 0 ?
				<p>No messages</p>
			 :
				messages.map( ( msg , index ) =>
					<div key={index}>
						<p>{msg}</p>
					</div>
				)
			}
			</div>
			<button onClick={clearMessages} className="ok-button">OK</button>
		</div>
	);
};

export default MessageBox;
