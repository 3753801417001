import React,{ useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { MessageContext } from './MessageContext';

import { makeFetchParamsPub } from './Utils.js';

import './NewOrder.css';

// const customStyles = {
  // content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
  // },
// };

// const handleInputChange = ( e , setter , regExp ) =>
// {
	// if( e.target.value.match( regExp ) != null )
		// setter( e.target.value );
// }

export default function NewOrder( props )
{
	const [ order , setOrder ] = React.useState(
	{
		id  	: "" ,
		market  : "" ,
		network : "" ,
		side    : "" ,
		type    : "limit" ,
	} );

	const [ markets  , setMarkets  ] = React.useState( undefined );
	const [ networks , setNetworks ] = React.useState( undefined );

	const { addMessage } = React.useContext( MessageContext );

	React.useEffect( () =>
	{
		fetchMarkets();
	} , [] );

	const fetchMarkets = () =>
	{
		fetch( ...makeFetchParamsPub( "markets" , undefined , "GET" ) )
		.then( res => res.json() )
		.then( out =>
		{
			if( out.status === "ok" && out.result )
			{
				setMarkets( out.result );
				console.log( out );
			}
		} )
		.catch( err =>
		{
			// alert( err )
		} );
	}

	const fetchNetworks = e =>
	{
		fetch( ...makeFetchParamsPub( "networks/" + order.market , undefined , "GET" ) )
		.then( res => res.json() )
		.then( out =>
		{
			if( out.status === "ok" && out.result )
			{
				setNetworks( out.result );
				console.log( out );
			}

		} )
		.catch( err =>
		{
			// alert( err )
		} );
	}

	const submitOredr = () =>
	{
		let o = { ...order, slippage: props.slippage };

		fetch( ...makeFetchParamsPub( "order" , o , "POST" ) )
		.then( res => res.json() )
		.then( out =>
		{
			if( out.status === "ok" && out.result )
			{
			}
			else if( out.status === "error" && out.error )
			{
				if( out.error.message && out.error.message.length )
					addMessage( out.error.message );
			}
		} )
		.catch( err =>
		{
			// alert( err )
		} );
	}

	const form = (s) =>
	{
		const submit = e =>
		{
			if( e )
				e.preventDefault();

			submitOredr();
			props.close();
		}

		const setOrderParam = ( name , e ) =>
		{
			setOrder( prevOrder => ( { ...prevOrder , [name]: e.target.value } ) );
		}

		const setValue = ( name , type , epattern , props , e ) =>
		{
			if( e.target.value.match( epattern ) != null )
			{
				setOrderParam( name , e );
				if( props.onChange1 )
					props.onChange1( e )
			}
		}

		const Input = ( title , name , type , epattern , pattern , props = {} ) =>
		{
			// if( order[ name ] === undefined )
				// order[ name ] = "";

			return(
				<div>
					<label htmlFor={ "input_" + name }>{title}</label>
					<input
						id			= { "input_" + name }
						name  		= { name 	 		}
						type		= { type 	 		}
						value		= { order[ name ]	}
						onChange	= { setValue.bind( this , name , type , epattern , props ) }
						autoComplete= { "off"  	 		}
						{ ...props }
					/>
				</div>);
		}

		return(
			<form onSubmit = { submit }>

				{ Input( "ID" , "id" , "text" , "^\\d{0,10}$" , "^\\d{4,10}$" ) }

				<div>
					<label>Side</label>
					<select value={order.side} onChange={ setOrderParam.bind( this , "side" ) } >
						<option value=""></option>
						<option value="buy">Buy</option>
						<option value="sell">Sell</option>
					</select>
				</div>

				{ Input( "Market" 	, "market" 	, "text"   , "^[a-zA-Z_\\-]{0,10}$" 	, "^[a-zA-Z_\\-]{5,10}$" ,  { list:"syms" , style:{ textTransform: "uppercase" } , onBlur : fetchNetworks } ) }
				{ Input( "Amount" 	, "amount" 	, "number" , "^[\\d\\.]{0,16}$" 		, "^[\\d\\.]{1,16}$" ) }
				{ Input( "Price" 	, "price" 	, "number" , "^[\\d\\.]{0,16}$" 		, "^[\\d\\.]{1,16}$" ) }
				{ Input( "Address" 	, "address" , "text" , "^\\w{0,80}$" , "^w{20,80}$" ) }
				{ Input( "Network" 	, "network" , "text"   , "^[a-zA-Z_\\-\\d]{0,10}$" 	, "^[a-zA-Z_\\-]{6,10}$"  , { list:"nets" } ) }
				{ Input( "Memo/Tag" , "memo" 	, "text" , "^\\w{0,80}$" , "^w{20,80}$" ) }

				<datalist id="syms" >
					{ markets && markets.map( (market,index) => <option key={index} value={market}>{market}</option> ) }
				</datalist>

				<datalist id="nets" >
					{ networks && networks.map( (network,index) => <option key={index} value={network}>{network}</option> ) }
				</datalist>

				<div className="button-container">
					<button type="submit" >submit</button>
					<button type="button" onClick={props.close}>close</button>
				</div>
			</form>
		);
	}

	return(
		<Modal 	isOpen={props.isOpen}
				// onAfterOpen={afterOpenModal}
				onRequestClose={props.close}
				shouldCloseOnOverlayClick={ false }
				// style={customStyles}
				className = "Modal"
				overlayClassName="Overlay"
				contentLabel="Example Modal" >

			<h4>New Order</h4>
			{ form() }
		</Modal>
	);
}




